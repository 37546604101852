const TableSearchBar = (elementName = null) => {
    const elemName = elementName != null ? elementName : '.cqc-search-bar';

    function onInputHandler(e, table) {
        const query = e.target.value.toUpperCase();
        const tableRows = table.getElementsByTagName('tr');

        // Loop through all rows, and hide those who don't match the search query or are excluded
        for (let i = 0; i < tableRows.length; i++) {
            const tdElems = tableRows[i].getElementsByTagName('td');

            if (tdElems.length === 0) continue;

            let matchFound = false;
            for (let tdElem of tdElems) {
                const isExcluded = tdElem.dataset.cqcTableSearchExclude != null;

                if (!isExcluded) {
                    const txtValue = tdElem.textContent || tdElem.innerText;

                    if (txtValue.toUpperCase().indexOf(query) > -1) {
                         matchFound = true;
                         break;
                    }
                }
            }

            tableRows[i].style.display = matchFound ? '' : 'none';
        }
    }

    function init() {
        const searchBars = document.querySelectorAll(elemName);

        searchBars.forEach(searchBar => {
            const searchInputElem = searchBar.querySelector("input[type=search]");
            const tableToSearchElem = document.querySelector(searchInputElem.dataset.cqcTableSearch);

            searchInputElem.addEventListener('input', e => onInputHandler(e, tableToSearchElem));
        });
    }

    return {
        init,
    };
};

export default TableSearchBar;
