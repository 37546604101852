// import 'jquery-validation';
// import 'jquery-validation-unobtrusive';
import 'jquery.maskedinput/src/jquery.maskedinput';
// import 'jquery-ui/jquery-ui';
// import 'jquery-ajax-unobtrusive';
import 'bootstrap';
import moment from 'moment-timezone';
import './vendor/breakpoints/1.0/breakpoints';
import * as pbi from 'powerbi-client';
import 'select2';
import 'jquery-slimscroll';
import './vendor/jquery-slimscroll-horizontal/0.6.5/jquery.slimscroll.horizontal';
import App from './app';
import * as alerts from './cqc/Alerts';
import TableSearchBar from './components/TableSearchBar';

window.moment = moment;
window['powerbi-client'] = pbi;

function requireAll(r) {
    r.keys().forEach(r);
}

// Import all SVGs from img directory to be processed by Webpack (and prevent tree shake)
requireAll(require.context('../Content/img/', true, /^((?!font).)*\.svg$/));

Object.keys(alerts).forEach((alertsKey) => {
    window[alertsKey] = alerts[alertsKey];
});

const validatorConfig = {
    validClass: 'is-valid',
    errorClass: 'is-invalid',

};
$.validator.setDefaults(validatorConfig);
$.validator.unobtrusive.options = validatorConfig;

App.init(); // Init layout

// <!-- AWB SEARCH-->
$('#txtawbsearch').mask('***-99999999');

const menuToggle = document.getElementById('menu-toggle');
const sidebarWrapper = document.getElementById('sidebar');
const sidebarBreakpoint = 768;
const sidebarOpenClass = 'sidebar-open';
const sidebarCloseClass = 'sidebar-close';

if (menuToggle) {
    menuToggle.addEventListener('click', () => {
        if (sidebarWrapper.offsetWidth === 0) {
            if (!(window.innerWidth >= sidebarBreakpoint)) {
                sidebarWrapper.classList.add(sidebarOpenClass);
            }

            sidebarWrapper.classList.remove(sidebarCloseClass);
        } else {
            if (!(window.innerWidth <= sidebarBreakpoint)) {
                sidebarWrapper.classList.add(sidebarCloseClass);
            }
            sidebarWrapper.classList.remove(sidebarOpenClass);
        }
    });
}

TableSearchBar().init();
