var currentLogID;

function displayEmail(id) {
    if (!popup.IsVisible())
        popup.Show();
    $('#EmailContent').html('');
    $.get("AlertEmail", { 'id': id }, function (po, st, xhr) {
        var body = po.substring(po.indexOf("<h1"), po.indexOf("</body>"));
        $('#EmailContent').html(body);
    });

}


function OnGetRowValues(Value) {

    console.log(Value);
   
}

function OnDetailGridBeginCallback(s, e) {
    e.customArgs["_Id"] = currentLogID;
}

function OnDetailGridEndCallback(s, e) {
    if (!popup.IsVisible())
        popup.Show();
}

export { displayEmail, OnGetRowValues, OnDetailGridBeginCallback, OnDetailGridEndCallback };
